const SEARCH_KEYS = [
  { name: 'data.firstname', weight: 2 },
  { name: 'data.lastname', weight: 2 },
  'data.nickname',
  'data.infos',
  'data.birth.date',
  { name: 'data.birth.place', weight: 0.5 },

  'data.birth.reference',
  'data.death.date',
  { name: 'data.death.place', weight: 0.5 },

  'data.death.reference',
];

export const SEARCH_LIMIT = 30;

export const SEARCH_CONFIG = {
  minMatchCharLength: 3,
  findAllMatches: true,
  threshold: 0.4,
  ignoreLocation: true,
  keys: SEARCH_KEYS,
};
