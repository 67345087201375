import { PersonEvent } from '@kavrillon/tree-core';

interface IProps {
  event: PersonEvent;
}

export const PersonEventDate = ({ event: { approximative, date } }: IProps) => {
  const d = date ? new Date(date) : null;
  if (!d) return <></>;
  if (approximative) return <>vers {d.getFullYear()}</>;

  const literal = d.toLocaleDateString('fr-FR', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });

  return <>le {literal}</>;
};
