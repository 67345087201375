import { isNumeric, isString } from './object';

export const sortBy = <T>(
  list: T[],
  getValueFn: (v: T) => string | number | null,
  sorting: 'asc' | 'desc' = 'asc'
): T[] => {
  const sort = sorting === 'desc' ? -1 : 1;

  return list
    .map((i) => ({ i, v: getValueFn(i) }))
    .sort(({ v: v1 }, { v: v2 }) => {
      if (!v1 && !v2) return 0;
      if (!v1) return 1;
      if (!v2) return -1;

      if (isString(v1) && isString(v2)) {
        return (
          sort *
          (v1 as unknown as string)
            .toLowerCase()
            .localeCompare((v2 as unknown as string).toLowerCase())
        );
      }

      if (isNumeric(v1) && isNumeric(v2)) {
        return sort * ((v1 as unknown as number) - (v2 as unknown as number));
      }

      return 1;
    })
    .map((i) => i.i);
};

export const notEmpty = <T>(value: T | null | undefined): value is T => {
  return value !== null && value !== undefined;
};
