import s from './detail.module.scss';

import { PersonDetail } from 'modules/person';
import { PersonNode } from '..';
import { PersonLinks, PersonUnions, PersonRefs } from '.';

interface IProps {
  node: PersonNode;
}

export const PersonNodeDetail = ({ node }: IProps) => (
  <>
    <div>
      <PersonDetail person={node.data} />
    </div>
    <div>
      {node.parents.length > 0 && (
        <div className={s.block}>
          <PersonLinks title="Parents" links={node.parents} />
        </div>
      )}

      {node.unions.length > 0 && (
        <div className={s.block}>
          <PersonUnions unions={node.unions} />
        </div>
      )}

      {node.siblings.length > 0 && (
        <div className={s.block}>
          <PersonLinks title={'Fratrie'} links={node.siblings} />
        </div>
      )}

      {node.children.length > 0 && (
        <div className={s.block}>
          <PersonLinks title={'Enfants'} links={node.children} />
        </div>
      )}

      <div className={s.block}>
        <PersonRefs node={node} />
      </div>
    </div>
  </>
);
