import React from 'react';
import s from './icon.module.scss';

interface IProps {
  children: React.ReactChild | React.ReactChild[];
}

export const Icon = ({ children }: IProps) => (
  <svg
    className={s.icon}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    {children}
  </svg>
);
