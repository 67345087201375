import * as d3 from 'd3';
import {
  AnyNode,
  AnyNodeLink,
  RenderingNode,
  NodeRenderer,
  TreeDepth,
} from '.';

const DURATION = 500;
const SEPARATION = 0.5;
const SPACING = 30;
const ACTIONS_HEIGHT = 44;
const ICON_SIZE = 18;

let CURRENT_X: number;
let CURRENT_Y: number;
let CURRENT_K: number;

export class TreeRenderer {
  private ref: HTMLElement;
  private childrenTree?: any;
  private parentsTree?: any;
  private relativesTree?: any;
  private childSource?: any;
  private parentSource?: any;
  private relativeSource?: any;
  private svg: any;
  private zoom: any;
  private container: any;

  private nodeRenderer: NodeRenderer;
  private showDetail?: (n: AnyNode) => void;
  private showNode?: (n: AnyNode) => void;

  constructor(
    ref: HTMLElement,
    nodeRenderer: NodeRenderer,
    showNode?: (n: AnyNode) => void,
    showDetail?: (n: AnyNode) => void
  ) {
    this.ref = ref;
    this.nodeRenderer = nodeRenderer;
    this.showNode = showNode;
    this.showDetail = showDetail;
    this.svg = d3.select(this.ref);
  }

  load(root: AnyNode, depth: TreeDepth) {
    const nodeSize = this.nodeRenderer.size;

    // Clean existing elements
    this.svg.select('g.container').remove();

    // Initialization
    this.container = this.svg.append('g').attr('class', 'container');
    const svgHeight = this.ref.clientHeight;
    const svgWidth = this.ref.clientWidth;

    // Default positionning
    const initialX = svgWidth / 2;
    const initialY = svgHeight - SPACING - nodeSize / 2;
    const initialK = 1;

    // Current positionning (default or previous one)
    let currentX = CURRENT_X || initialX;
    let currentY = CURRENT_Y || initialY;
    let currentK = CURRENT_K || initialK;

    // Zoom
    this.zoom = d3.zoom().on('zoom', ({ transform: t }: any) => {
      CURRENT_X = t.x;
      CURRENT_Y = t.y;
      CURRENT_K = t.k;
      this.container.attr('transform', t);
    });

    this.svg.call(this.zoom as any);
    this.svg.call(
      this.zoom.transform as any,
      d3.zoomIdentity.translate(currentX, currentY).scale(currentK)
    );

    // data sources
    const nodeSizes: [number, number] = [
      nodeSize * 2 + SPACING,
      nodeSize + SPACING,
    ];

    this.parentsTree = d3
      .tree<AnyNode>()
      .nodeSize(nodeSizes)
      .separation(() => SEPARATION);

    this.relativesTree = d3
      .tree<AnyNode>()
      .nodeSize(nodeSizes)
      .separation(() => SEPARATION);

    this.childrenTree = d3
      .tree<AnyNode>()
      .nodeSize(nodeSizes)
      .separation(() => SEPARATION);

    this.parentSource = this.getSource(root, depth.parent, 'parents');
    this.childSource = this.getSource(root, depth.child, 'children');
    this.relativeSource = this.getSource(root, 1, 'relatives');

    this.render();
    this.fitZoom();
  }

  fitZoom = () => {
    const svgWidth = this.svg.node().clientWidth;
    const svgHeight = this.svg.node().clientHeight;

    const { height, width, y } = this.container.node().getBBox();
    const midY = y + height / 2;
    if (width === 0 || height === 0) return;

    const scale = 0.95 / (height / svgHeight);
    const translate = [svgWidth / 2, svgHeight / 2 - scale * midY];

    const transform = d3.zoomIdentity
      .translate(translate[0], translate[1])
      .scale(scale);

    this.svg
      .transition()
      .duration(DURATION)
      .call(this.zoom.transform as any, transform);
  };

  collapseAll = () => {
    this.parentSource.children?.forEach(this.collapseTree);
    this.childSource.children?.forEach(this.collapseTree);
    this.render();
  };

  uncollapseAll = () => {
    this.uncollapseTree(this.parentSource);
    this.uncollapseTree(this.childSource);
    this.render();
  };

  private render = () => {
    this.parentsTree(this.parentSource);
    this.relativesTree(this.relativeSource);
    this.childrenTree(this.childSource);

    this.drawLinks(this.parentSource, 1);
    this.drawLinks(this.childSource, -1);

    this.drawNodes(this.parentSource, 1);
    this.drawNodes(this.relativeSource, 0);
    this.drawNodes(this.childSource, -1);
  };

  private drawNodes = (source: d3.HierarchyNode<AnyNode>, dir: number) => {
    const nodeSize = this.nodeRenderer.size;
    const nodeRadius = this.nodeRenderer.radius;
    const root = source.data;
    const nodes = source.descendants();

    let type = 'similars';
    if (dir === 1) type = 'parents';
    if (dir === -1) type = 'children';

    const nodePersons = this.container
      .selectAll('.node.' + type)
      .data(nodes, (d: RenderingNode) => d.data.uuid);

    // Enter
    const nodePersonEnter = nodePersons
      .enter()
      .append('g')
      .attr('class', ({ data }: RenderingNode) => this.getNodeClass(data, type))
      .attr('id', ({ data }: RenderingNode) => `Node${data.uuid}`)
      .attr('x', -(nodeSize / 2))
      .attr('y', -(nodeSize / 2))
      .attr('width', nodeSize)
      .attr('height', nodeSize)
      .attr('opacity', 0)
      .attr('transform', (d: RenderingNode) =>
        this.getNodeTransform(d, dir, root)
      );

    // node bg
    nodePersonEnter
      .append('rect')
      .attr('class', 'rect')
      .attr('x', -(nodeSize / 2))
      .attr('y', -(nodeSize / 2))
      .attr('rx', nodeRadius)
      .attr('width', nodeSize)
      .attr('height', nodeSize);

    // footer bg
    nodePersonEnter
      .append('rect')
      .attr('class', 'actions')
      .attr('x', -nodeSize / 2)
      .attr('y', nodeSize / 2 - ACTIONS_HEIGHT)
      .attr('rx', nodeRadius)
      .attr('width', nodeSize)
      .attr('height', ACTIONS_HEIGHT);

    // show info button
    if (this.showDetail) {
      nodePersonEnter
        .append('rect')
        .attr('class', 'icon')
        .attr('x', nodeSize / 4 - ICON_SIZE / 2)
        .attr('y', nodeSize / 2 - ACTIONS_HEIGHT / 2 - ICON_SIZE / 2)
        .attr('width', ICON_SIZE)
        .attr('height', ICON_SIZE)
        .attr('cursor', 'pointer')
        .attr('fill', 'url(#Info)');

      nodePersonEnter
        .append('rect')
        .attr('class', 'info')
        .attr('x', 0)
        .attr('y', nodeSize / 2 - ACTIONS_HEIGHT)
        .attr('width', nodeSize / 2)
        .attr('height', ACTIONS_HEIGHT)
        .attr('cursor', 'pointer')
        .attr('fill', 'transparent')
        .on('click', (_: any, n: RenderingNode) => this.showDetail!(n.data));
    }

    // hide/show button
    nodePersonEnter
      .append('rect')
      .attr('class', 'icon toggleIcon')
      .attr('x', -nodeSize / 4 - ICON_SIZE / 2)
      .attr('y', nodeSize / 2 - ACTIONS_HEIGHT / 2 - ICON_SIZE / 2)
      .attr('width', ICON_SIZE)
      .attr('height', ICON_SIZE)
      .attr('cursor', 'pointer')
      .attr('fill', this.getToggleFill)
      .attr('visibility', this.getToggleVisibility);

    nodePersonEnter
      .append('rect')
      .attr('class', 'toggle')
      .attr('x', -nodeSize / 2)
      .attr('y', nodeSize / 2 - ACTIONS_HEIGHT)
      .attr('width', nodeSize / 2)
      .attr('height', ACTIONS_HEIGHT)
      .attr('cursor', 'pointer')
      .attr('fill', 'transparent')
      .attr('visibility', this.getToggleVisibility)
      .on('click', (_: any, n: RenderingNode) => this.toggleCollapse(n));

    // content rendering
    this.nodeRenderer.render(nodePersonEnter);

    // clickable layer
    if (this.showNode) {
      nodePersonEnter
        .append('rect')
        .attr('x', -(nodeSize / 2))
        .attr('y', -(nodeSize / 2))
        .attr('rx', nodeRadius)
        .attr('width', nodeSize)
        .attr('height', nodeSize - ACTIONS_HEIGHT)
        .attr('cursor', 'pointer')
        .attr('fill', 'transparent')
        .on('click', (_: any, n: RenderingNode) => this.showNode!(n.data));
    }

    // Update
    const nodePersonUpdate = nodePersonEnter.merge(nodePersons as any);

    nodePersonUpdate
      .transition()
      .duration(DURATION)
      .attr('opacity', 1)
      .attr('transform', (d: RenderingNode) =>
        this.getNodeTransform(d, dir, root)
      );

    nodePersonUpdate.select('rect.toggleIcon').attr('fill', this.getToggleFill);

    nodePersonUpdate
      .select('rect')
      .style('fill', function ({ data }: RenderingNode) {
        if (data.isRoot) return 'url("#Root")';
        if (data.isSibling) return 'url("#Sibling")';
        if (data.isChild) return 'url("#Child")';
        if (data.isUnion) return 'url("#Union")';
        return data.parents.length > 0 ? 'url("#Parent")' : 'url("#ParentEnd")';
      });

    // Exit
    nodePersons
      .exit()
      .transition()
      .duration(DURATION)
      .attr('opacity', 0)
      .remove();
  };

  private drawLinks = (source: any, direction: number) => {
    const nodeSize = this.nodeRenderer.size;
    const links = source.descendants().slice(1);
    const className = direction === 1 ? 'parentLink' : 'childLink';

    const nodeLinks = this.container
      .selectAll(`.link.${className}`)
      .data(links, (d: any) => d.data.uuid);

    // Enter
    const nodeLinkEnter = nodeLinks
      .enter()
      .append('path')
      .attr('class', `link ${className}`)
      .attr('opacity', 0)
      .attr('d', (d: any) => this.elbow(d, d.parent, nodeSize, direction));

    // Update
    const nodeLinkUpdate = nodeLinkEnter.merge(nodeLinks as any);

    nodeLinkUpdate
      .transition()
      .duration(DURATION)
      .attr('opacity', 1)
      .attr('d', (d: any) => this.elbow(d, d.parent, nodeSize, direction));

    // Exit
    nodeLinks
      .exit()
      .transition()
      .duration(DURATION / 2)
      .attr('opacity', 0)
      .remove();
  };

  private getSource = (root: AnyNode, max: number, key: keyof AnyNode): any => {
    // we don't display the last depth, cause we don't have loaded it's relations and it will generate an empty item in the detailed view
    let depth = Math.min(this.getMaxDepth(root, key), max);

    return d3.hierarchy(root, (n) =>
      n.depth < depth ? n[key].map((l: AnyNodeLink) => l.node) : []
    );
  };

  private getNodeTransform = (d: any, dir: number, n: AnyNode): string => {
    const nodeSize = this.nodeRenderer.size;
    let gap = 0;
    if (dir === 0) {
      // Relatives (siblings & unions): we move nodes to be aligned with root node
      const total = n.relatives.length;
      const rootPos = n.relatives.findIndex((s: any) => n.uuid === s.node.uuid);
      const pos = Math.trunc(total / 2) - rootPos;
      const size = nodeSize + SPACING / 2;
      gap = pos * size - (total % 2 === 0 ? size / 2 : 0);
    }
    return 'translate(' + (d.x + gap) + ',' + -1 * dir * d.y + ')';
  };

  private getNodeClass = (n: AnyNode, type: string): string => {
    let className = `node ${type}`;
    if (n.isRoot) className += ` root`;
    if (n.isChild) className += ` child`;
    if (n.isParent) className += ` parent`;
    if (n.isUnion) className += ` union`;
    if (n.isSibling) className += ` sibling`;
    return className;
  };

  private collapseTree = (d: RenderingNode) => {
    d.children?.forEach(this.collapseTree);
    this.collapse(d);
  };

  private uncollapseTree = (d: RenderingNode) => {
    this.uncollapse(d);
    d.children?.forEach(this.uncollapseTree);
  };

  private toggleCollapse = (n: RenderingNode) => {
    this.isCollapsed(n) ? this.uncollapse(n) : this.collapse(n);

    d3.select(`#Node${n.data.uuid} .toggleIcon`).attr(
      'fill',
      this.getToggleFill(n)
    );

    this.render();
  };

  private collapse = (d: RenderingNode) => {
    if (!d.children) return;
    d._children = d.children;
    d.children = undefined;
  };

  private uncollapse = (d: RenderingNode) => {
    if (!d._children) return;
    d.children = d._children;
    d._children = undefined;
  };

  private isCollapsed = (d: RenderingNode) => !!d._children;

  // Link path calculation
  private elbow = (source: any, dest: any, height: number, dir: number) => {
    return (
      'M' +
      dest.x +
      ',' +
      -dir * (dest.y + height / 2) +
      'V' +
      -dir * (source.y + (dest.y - source.y) / 2) +
      'H' +
      source.x +
      'V' +
      -dir * source.y
    );
  };

  private flatten = (node: AnyNode, key: keyof AnyNode): AnyNode[] => {
    return node[key].flatMap(({ node }: AnyNodeLink) => [
      node,
      ...this.flatten(node, key),
    ]);
  };

  private getMaxDepth = (node: AnyNode, key: keyof AnyNode): number => {
    return this.flatten(node, key).reduce((max, { depth }) => {
      if (depth > max) return depth;
      return max;
    }, 0);
  };

  private getToggleVisibility = (d: RenderingNode) => {
    return d.depth > 0 && d.data.canToggle && d.data.parents.length > 0
      ? 'visible'
      : 'hidden';
  };

  private getToggleFill = (d: RenderingNode) => {
    return this.isCollapsed(d) ? 'url(#Show)' : 'url(#Hide)';
  };
}
