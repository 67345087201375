import s from './viewer.module.scss';

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'shared/store';
import { Modal } from 'shared/components';
import { IconNext, IconPrevious } from 'shared/icons';
import { setPictures } from '..';
import { useKeyPress } from 'shared/hooks';

export const PictureViewer = () => {
  const dispatch = useDispatch();
  const items = useSelector((state: RootState) => state.picture.items);
  const [index, set] = useState(0);

  useEffect(() => set(0), [items]);
  useKeyPress(['ArrowLeft'], () => index > 0 && set(index - 1));
  useKeyPress(['ArrowRight'], () => index < items.length - 1 && set(index + 1));

  return (
    <>
      {items.length > 0 && (
        <Modal onClose={() => dispatch(setPictures([]))}>
          <div className={s.wrapper}>
            <img className={s.img} src={items[index]} alt="" />

            {items.length > 1 && (
              <div className={s.menu}>
                <button
                  className={s.cta}
                  onClick={() => set(index - 1)}
                  disabled={index === 0}
                >
                  <IconPrevious />
                </button>
                <button
                  className={s.cta}
                  onClick={() => set(index + 1)}
                  disabled={index === items.length - 1}
                >
                  <IconNext />
                </button>
              </div>
            )}
          </div>
        </Modal>
      )}
    </>
  );
};
