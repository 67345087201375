import s from './tree.module.scss';

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  animated,
  config as springConfig,
  useChain,
  useSpring,
  useSpringRef,
  useTransition,
} from 'react-spring';
import { PersonDatabase, PersonItem } from '@kavrillon/tree-core';
import { Loader } from 'shared/components';
import { RootState } from 'shared/store';
import { useDidMountEffect, useMediaQuery } from 'shared/hooks';
import { PictureViewer } from 'modules/picture';
import { AnyNode, Tree } from 'modules/tree';
import {
  PersonNode,
  PersonNodeDetail,
  PersonNodeRenderer,
  PersonTreeLoader,
  setDetailedNode,
} from 'modules/person-node';
import { defaultOffStyle, defaultOnStyle } from 'config/animation';
import { MEDIA_DESKTOP } from 'config/media';
import { useKeyPress } from 'shared/hooks';
import { useGetPersonsQuery } from 'shared/services';

const NODE_RENDERER = new PersonNodeRenderer();

export const PageTree = () => {
  const { data } = useGetPersonsQuery();
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isDesktop = useMediaQuery(MEDIA_DESKTOP);

  const detailNode = useSelector(
    (state: RootState) => state.personNode.detailed
  );
  const depth = useSelector((state: RootState) => state.tree.depth);
  const [removing, setRemoving] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [detailLoading, setDetailLoading] = useState<boolean>(false);
  const [treeLoader, setTreeLoader] = useState<PersonTreeLoader | null>(null);
  const [rootNode, setRootNode] = useState<PersonNode | null>(null);

  // keyboard shortcut
  useKeyPress(['a'], () => goTo(rootNode?.parents[0]?.node));
  useKeyPress(['e'], () => goTo(rootNode?.parents[1]?.node));
  useKeyPress(['s'], () => goTo(rootNode?.children[0]?.node));
  useKeyPress(['d'], () => goTo(getNextSibling(rootNode)));
  useKeyPress(['q'], () => goTo(getPrevSibling(rootNode)));
  useKeyPress(['z'], () => goTo(rootNode?.unions[0]?.node));

  useEffect(() => {
    if (!data) return;
    setLoading(true);
    setTreeLoader(new PersonTreeLoader(data));
  }, [data]);

  useDidMountEffect(() => {
    const loadRoot = async () => {
      if (!data || !treeLoader) return;

      setLoading(true);
      setDetailLoading(true);

      const item = getItemFromRoute(data, params.person);
      if (!item) return;

      const node = (await treeLoader.load(item.uuid, depth)) || null;
      setRootNode(node);
      setLoading(false);

      if (detailNode) dispatch(setDetailedNode(node));
    };

    loadRoot();
  }, [treeLoader, params, depth]); // eslint-disable-line

  useDidMountEffect(() => {
    setDetailLoading(false);
  }, [detailNode]);

  // methods
  const goTo = (n: PersonNode | undefined) => {
    if (n) navigate(`/p/${n.uuid}`);
  };

  // animations
  const removingStyles = useSpring({
    config: springConfig.default,
    from: { opacity: 1 },
    to: { opacity: removing ? 0 : 1 },
  });

  const containerApi = useSpringRef();
  const detailContainerStyles = useSpring({
    ref: containerApi,
    config: springConfig.stiff,
    from: { width: '0', opacity: 0 },
    to: {
      width: detailNode ? (isDesktop ? '400px' : '100%') : '0',
      opacity: detailNode ? 1 : 0,
    },
  });

  const transApi = useSpringRef();
  const detailTransition = useTransition(detailLoading ? null : detailNode, {
    ref: transApi,
    from: { ...defaultOffStyle },
    enter: { ...defaultOnStyle },
    leave: { ...defaultOffStyle },
  });

  useChain(
    !!detailNode && !detailLoading
      ? [containerApi, transApi]
      : [transApi, containerApi],
    [0, 0.4]
  );

  const modifiers = detailNode ? s._detailed : '';

  // rendering
  return (
    <div className={s.page + ' ' + modifiers}>
      <div className={s.in}>
        <Loader loading={loading} />

        {rootNode && (
          <animated.div className={s.tree} style={removingStyles}>
            <Tree
              root={rootNode}
              depth={depth}
              nodeRenderer={NODE_RENDERER}
              search={() => {
                setRemoving(true);
                setTimeout(() => navigate('/'), 320);
              }}
              showDetail={(n: AnyNode) => dispatch(setDetailedNode(n))}
              showNode={(n: AnyNode) => navigate(`/p/${n.uuid}`)}
              toggleDetail={() =>
                dispatch(setDetailedNode(detailNode ? null : rootNode))
              }
            />
          </animated.div>
        )}
      </div>

      <animated.div className={s.detail} style={detailContainerStyles}>
        <div className={s.wrapper}>
          <div className={s.info}>
            <div className={s.detailloader}>
              <Loader loading={loading} />
            </div>

            {detailTransition(
              (style, item) =>
                item && (
                  <animated.div className={s.infoContent} style={style}>
                    <PersonNodeDetail node={item} />
                  </animated.div>
                )
            )}
          </div>
          <div className={s.footer}>
            <button
              className={s.close}
              onClick={() => dispatch(setDetailedNode(null))}
            >
              Fermer
            </button>
          </div>
        </div>
      </animated.div>

      <PictureViewer />
    </div>
  );
};

/**
 * INTERNALS
 */

const getItemFromRoute = (
  database: PersonDatabase,
  query: string | undefined
): PersonItem | null => {
  return (
    database?.find(
      ({ uuid, data }) => uuid === query || data?.alias === query
    ) || null
  );
};

const getNextSibling = (node: PersonNode | null): PersonNode | undefined => {
  const i = getCurrentSiblingIndex(node);
  if (typeof i === 'undefined') return;
  const count = node?.siblings.length!;
  const newIndex = (i + 1) % count;
  return node?.siblings[newIndex].node;
};

const getPrevSibling = (node: PersonNode | null): PersonNode | undefined => {
  const i = getCurrentSiblingIndex(node);
  if (typeof i === 'undefined') return;
  const count = node?.siblings.length!;
  const newIndex = (((i - 1) % count) + count) % count;
  return node?.siblings[newIndex].node;
};

const getCurrentSiblingIndex = (
  node: PersonNode | null
): number | undefined => {
  return node?.siblings.findIndex((s) => s.node.uuid === node?.uuid);
};
