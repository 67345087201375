import { createSlice } from '@reduxjs/toolkit';
import { TreeState } from './types';

const initialState: TreeState = { depth: { child: 1, parent: 2 } };

export const treeSlice = createSlice({
  name: 'tree',
  initialState,
  reducers: {
    addChildLevel: (state) => {
      state.depth.child++;
    },
    removeChildLevel: (state) => {
      if (state.depth.child > 1) state.depth.child--;
    },
    addParentLevel: (state) => {
      state.depth.parent++;
    },
    removeParentLevel: (state) => {
      if (state.depth.parent > 1) state.depth.parent--;
    },
  },
});

export const {
  addChildLevel,
  removeChildLevel,
  addParentLevel,
  removeParentLevel,
} = treeSlice.actions;

export default treeSlice.reducer;
