import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PictureState } from '.';

const initialState: PictureState = { items: [] };

export const pictureSlice = createSlice({
  name: 'picture',
  initialState,
  reducers: {
    setPictures: (state, action: PayloadAction<string[]>) => {
      state.items = action.payload;
    },
  },
});

export const { setPictures } = pictureSlice.actions;
export default pictureSlice.reducer;
