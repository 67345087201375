import s from './loader.module.scss';

import { animated, useTransition } from 'react-spring';
import { opacityTransition } from 'config/animation';

interface IProps {
  loading: boolean;
}

export const Loader = ({ loading }: IProps) => {
  const transition = useTransition(loading, opacityTransition);

  return (
    <>
      {transition(
        (style, item) =>
          item && (
            <animated.div className={s.wrapper} style={style}>
              <svg className={s.loader} viewBox="0 0 38 38">
                <g fill="none" transform="translate(1 1)">
                  <circle strokeOpacity=".2" cx="18" cy="18" r="18" />
                  <path d="M36 18c0-9.94-8.06-18-18-18">
                    <animateTransform
                      attributeName="transform"
                      type="rotate"
                      from="0 18 18"
                      to="360 18 18"
                      dur="1s"
                      repeatCount="indefinite"
                    />
                  </path>
                </g>
              </svg>
            </animated.div>
          )
      )}
    </>
  );
};
