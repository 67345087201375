import { Database, Item } from '@kavrillon/tree-core';
import { Node, NodeLink, Sorter, TreeDepth } from '.';
import { notEmpty, sortBy } from 'shared/utils/array';
import { wait } from 'shared/utils/promise';

export class TreeDataLoader<T, P, U> {
  data: Database<T, P, U>; // items database
  rootId: string; // id of the root node
  depth: TreeDepth; // max levels of parents and children from root node
  parentSort?: Sorter<NodeLink<T, P, U, P>>;
  childSort?: Sorter<NodeLink<T, P, U>>;
  siblingSort?: Sorter<NodeLink<T, P, U>>;
  unionSort?: Sorter<NodeLink<T, P, U, U>>;

  constructor(
    data: Database<T, P, U>,
    parentSort?: Sorter<NodeLink<T, P, U, P>>,
    childSort?: Sorter<NodeLink<T, P, U>>,
    siblingSort?: Sorter<NodeLink<T, P, U>>,
    unionSort?: Sorter<NodeLink<T, P, U, U>>
  ) {
    this.data = data;
    this.parentSort = parentSort;
    this.childSort = childSort;
    this.siblingSort = siblingSort;
    this.unionSort = unionSort;
    this.rootId = '';
    this.depth = { child: 0, parent: 0 };
  }

  async load(rootId: string, depth: TreeDepth) {
    this.rootId = rootId;
    this.depth = depth;
    const rootNode = await this.loadNode(this.rootId, true);
    if (rootNode) this.setTreeIds(rootNode);
    return rootNode;
  }

  private async loadNode(
    id: string,
    follow: boolean,
    refId: string = id,
    depth: number = 0,
    dir: number | undefined | null = 1
  ): Promise<Node<T, P, U> | undefined> {
    // due to reccursivity, this script may be time consuming
    // we make it asynchronous to let the browser doing stuff during calculation
    await wait(5);

    // search for uuid
    const item = this.data.find((i) => i.uuid === id) || null;
    if (!item) return Promise.reject();

    const node = {
      uuid: item.uuid,
      data: { ...item.data } as T,
      refId,
      depth,
      isFirstNode: id === this.rootId && dir === 1 && depth === 0,
      isRoot: id === this.rootId,
      isChild: dir === -1,
      isSibling: dir === 0,
      isParent: dir === 1 && depth > 0,
      isUnion: dir === null,
      parents: [] as NodeLink<T, P, U, P>[],
      children: [] as NodeLink<T, P, U>[],
      siblings: [] as NodeLink<T, P, U>[],
      unions: [] as NodeLink<T, P, U, U>[],
      relatives: [] as NodeLink<T, P, U>[],
      canToggle: false,
    };

    if (follow) {
      node.parents = await this.loadParents(node, item);
      node.children = await this.loadChildren(node);
      node.siblings = await this.loadSiblings(node, item);
      node.unions = await this.loadUnions(node, item);
      node.relatives = this.getRelatives(node.siblings, node.unions);
    }

    if (
      (dir === 1 && depth < this.depth.parent && node.parents.length > 0) ||
      (dir === -1 && depth < this.depth.child && node.children.length > 0)
    ) {
      node.canToggle = true;
    }

    return Promise.resolve(node);
  }

  private async loadParents(
    n: Node<T, P, U>,
    { parents }: Item<T, P, U>
  ): Promise<NodeLink<T, P, U, P>[]> {
    if (!parents) return Promise.resolve([]);

    // changing direction: we reset the depth to one
    const newDepth = n.isChild ? 1 : n.depth + 1;
    // changing direction: we change the dep ref
    const newRefId = n.isChild ? n.uuid : n.refId;

    // we follow links of the root ref id only
    const follow = newRefId === this.rootId && n.depth < this.depth.parent;

    const promises = parents.map(async ({ uuid, data }) => {
      const node = await this.loadNode(uuid, follow, newRefId, newDepth, 1);
      return node ? { node, data: data as P } : null;
    });

    const result = (await Promise.all(promises)).filter(notEmpty);

    if (this.parentSort) {
      return sortBy<NodeLink<T, P, U, P>>(
        result,
        this.parentSort.fn,
        this.parentSort.type
      );
    }
    return result;
  }

  private async loadChildren(n: Node<T, P, U>): Promise<NodeLink<T, P, U>[]> {
    // changing direction: we reset the depth to one
    const newDepth = n.isParent ? 1 : n.depth + 1;
    // changing direction: we change the dep ref
    const newRefId = n.isParent ? n.uuid : n.refId;

    // we follow links of the root ref id only
    const follow = newRefId === this.rootId && n.depth < this.depth.child;

    const promises = this.data
      .filter(({ parents }) =>
        parents?.map(({ uuid }) => uuid).includes(n.uuid)
      )
      .map(async ({ uuid }) => {
        const node = await this.loadNode(uuid, follow, newRefId, newDepth, -1);
        return node ? { node, data: null } : null;
      });

    const result = (await Promise.all(promises)).filter(notEmpty);

    if (this.childSort) {
      return sortBy<NodeLink<T, P, U>>(
        result,
        this.childSort.fn,
        this.childSort.type
      );
    }
    return result;
  }

  private async loadSiblings(
    n: Node<T, P, U>,
    item: Item<T, P, U>
  ): Promise<NodeLink<T, P, U>[]> {
    const follow = n.isFirstNode;
    const ids = item.parents?.map((p) => p.uuid);

    const promises = this.data
      .filter(({ parents }) => {
        return ids?.some((id) => parents?.map((p) => p.uuid).includes(id));
      })
      .map(async ({ uuid }) => {
        const node = await this.loadNode(uuid, follow, n.refId, 1, 0);
        return node ? { node, data: null } : null;
      });

    const result = (await Promise.all(promises)).filter(notEmpty);

    if (this.siblingSort) {
      return sortBy<NodeLink<T, P, U>>(
        result,
        this.siblingSort.fn,
        this.siblingSort.type
      );
    }
    return result;
  }

  private async loadUnions(
    n: Node<T, P, U>,
    { unions }: Item<T, P, U>
  ): Promise<NodeLink<T, P, U, U>[]> {
    if (!unions) return Promise.resolve([]);

    const follow = n.isFirstNode;
    const promises = unions?.map(async ({ uuid, data }) => {
      const node = await this.loadNode(uuid, follow, n.refId, 1, null);
      return node ? { node, data: data as U } : null;
    });

    const result = (await Promise.all(promises)).filter(notEmpty);

    if (this.unionSort) {
      return sortBy<NodeLink<T, P, U, U>>(
        result,
        this.unionSort.fn,
        this.unionSort.type
      );
    }
    return result;
  }

  private getRelatives(
    siblings: NodeLink<T, P, U>[],
    unions: NodeLink<T, P, U, U>[]
  ): NodeLink<T, P, U>[] {
    const index = siblings.findIndex(({ node }) => node.uuid === this.rootId);
    if (index === -1)
      return [...siblings, ...unions.map((u) => ({ ...u, data: null }))];

    return [
      ...siblings.slice(0, index + 1),
      ...unions.map((u) => ({ ...u, data: null })),
      ...siblings.slice(index + 1, siblings.length),
    ];
  }

  private setTreeIds(
    node: Node<T, P, U>,
    refNode: Node<T, P, U> | null = null,
    index: number = 0
  ): void {
    node.treeId = this.getParentNodeId(refNode, index);
    node.parents.forEach((p, i) => this.setTreeIds(p.node, node, i));
  }

  private getParentNodeId(
    refNode: Node<T, P, U> | null,
    index: number
  ): number {
    if (!refNode || !refNode.treeId) return 1;
    return refNode.treeId * 2 + index;
  }
}
