import { config } from 'react-spring';

export const defaultOffStyle = {
  config: config.default,
  opacity: 0,
  transform: 'translateY(-15px)',
};

export const defaultOnStyle = {
  config: config.default,
  opacity: 1,
  transform: 'translateY(0px)',
};

export const rightToLeftOffStyle = {
  config: config.default,
  opacity: 0,
  transform: 'translateX(-15px)',
};

export const rightToLeftOnStyle = {
  config: config.default,
  opacity: 1,
  transform: 'translateX(0)',
};

export const fadeOffStyle = {
  config: config.default,
  opacity: 0,
};

export const fadetOnStyle = {
  config: config.default,
  opacity: 1,
};

export const rightToLeftTransition = {
  from: rightToLeftOffStyle,
  enter: rightToLeftOnStyle,
  leave: rightToLeftOffStyle,
};

export const opacityTransition = {
  from: fadeOffStyle,
  enter: fadetOnStyle,
  leave: fadeOffStyle,
};

export const homeTopFrom = {
  opacity: 0,
  top: '55%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '300px',
  logoOpacity: 1,
  logoWidth: '200px',
  logoHeight: '232px',
  formHeight: '40px',
  formBorderRadius: '0px',
  fontWeight: '400',
  fontSize: '14px',
  resetOpacity: 0,
  resetTransform: 'translateY(-50%) scale(0)',
};

export const homeTopTo = (active: boolean) => ({
  opacity: 1,
  top: active ? '0%' : '50%',
  transform: active ? 'translate(-50%, 0%)' : 'translate(-50%,-50%)',
  width: active ? `${vwToPixel(100)}` : '300px',
  logoOpacity: active ? 0 : 1,
  logoWidth: active ? '0px' : '200px',
  logoHeight: active ? '0px' : '232px',
  formHeight: active ? '60px' : '40px',
  formBorderRadius: active ? '0px' : '8px',
  fontWeight: active ? '700' : '400',
  fontSize: active ? '18px' : '14px',
  resetOpacity: active ? 1 : 0,
  resetTransform: active
    ? 'translateY(-50%) scale(1)'
    : 'translateY(-50%) scale(0)',
});

export const homeResultFrom = {
  opacity: 0,
  scale: 0,
  height: '0px',
  marginBottom: '0px',
};

export const homeResultTo = (isDesktop: boolean) => ({
  opacity: 1,
  scale: 1,
  height: '130px',
  marginBottom: isDesktop ? '32px' : '16px',
});

export const homeContentFrom = { opacity: 0 };
export const homeContentTo = (active: boolean) => ({ opacity: active ? 1 : 0 });

const vwToPixel = (value: number) => `${(window.innerWidth * value) / 100}px`;
