import s from './home.module.scss';

import { FormEvent, MouseEvent, useEffect, useRef, useState } from 'react';
import {
  config,
  useChain,
  useSpring,
  useSpringRef,
  useTransition,
  animated,
} from 'react-spring';
import Fuse from 'fuse.js';
import { PersonItem } from '@kavrillon/tree-core';
import { SEARCH_CONFIG, SEARCH_LIMIT } from 'config/search';
import {
  homeContentFrom,
  homeContentTo,
  homeResultFrom,
  homeResultTo,
  homeTopFrom,
  homeTopTo,
} from 'config/animation';
import { MEDIA_DESKTOP } from 'config/media';
import { Logo } from 'shared/components';
import { IconClose } from 'shared/icons';
import { useMediaQuery } from 'shared/hooks';
import { useGetPersonsQuery } from 'shared/services';
import { PersonResult } from 'modules/person-database';

export const PageHome = () => {
  const { data } = useGetPersonsQuery();
  const refInput = useRef<HTMLInputElement>(null);
  const [total, setTotal] = useState<number>(0);
  const [results, setResults] = useState<PersonItem[] | null>(null);
  const [searcher, setSearcher] = useState<Fuse<PersonItem> | null>(null);
  const isDesktop = useMediaQuery(MEDIA_DESKTOP);

  // derivated state
  const isSearched = !!results;
  const hasMoreResults = isSearched && total! > results.length;

  useEffect(() => {
    if (!data) return;
    const fuse = new Fuse<PersonItem>(data, SEARCH_CONFIG);
    setSearcher(fuse);
  }, [data]);

  // handlers
  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (!refInput?.current || !searcher) return;
    const query = refInput.current.value;
    const results = searcher.search(query).map((r) => r.item);
    const firsts = results.filter((_, i) => i < SEARCH_LIMIT);

    setTotal(results.length);
    setResults(firsts);
    isDesktop && refInput.current.focus();
  };

  const onReset = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();

    setResults(null);
    if (refInput && refInput.current) {
      refInput.current.value = '';
      isDesktop && refInput.current.focus();
      !isDesktop && refInput.current.blur();
    }
  };

  // animations
  const topApi = useSpringRef();
  const topStyle = useSpring({
    ref: topApi,
    config: config.default,
    from: homeTopFrom,
    to: homeTopTo(isSearched),
  });

  const resultApi = useSpringRef();
  const resultTransition = useTransition(isSearched ? results : [], {
    ref: resultApi,
    trail: isSearched ? 20 : 1,
    from: homeResultFrom,
    enter: homeResultTo(isDesktop),
    leave: homeResultFrom,
  });

  const totalApi = useSpringRef();
  const totalStyle = useSpring({
    ref: totalApi,
    config: config.default,
    from: homeContentFrom,
    to: homeContentTo(isSearched),
  });

  const moreApi = useSpringRef();
  const moreStyle = useSpring({
    ref: moreApi,
    config: config.default,
    from: homeContentFrom,
    to: homeContentTo(hasMoreResults),
  });

  useChain(
    isSearched
      ? [topApi, totalApi, resultApi, moreApi]
      : [moreApi, resultApi, totalApi, topApi],
    [0, 0.4, 0.4, 0.8]
  );

  const countStyle = useSpring({ total: isSearched ? total : 0 });

  return (
    <div className={s.page}>
      <animated.div className={s.top} style={topStyle}>
        <animated.div
          style={{
            opacity: topStyle.logoOpacity,
            width: topStyle.logoWidth,
            height: topStyle.logoHeight,
          }}
        >
          <div className={s.logo}>
            <Logo />
          </div>
        </animated.div>

        <animated.form
          name="search"
          className={s.form}
          onSubmit={onSubmit}
          style={{
            height: topStyle.formHeight,
            borderRadius: topStyle.formBorderRadius,
          }}
        >
          <div className={s.field}>
            <input
              ref={refInput}
              type="text"
              id="Search"
              placeholder="Nom, prénom..."
              className={s.input}
              autoFocus
              required
              minLength={3}
            />
            <animated.button
              className={s.reset}
              style={{
                opacity: topStyle.resetOpacity,
                transform: topStyle.resetTransform,
              }}
              type="button"
              onClick={onReset}
            >
              <IconClose />
            </animated.button>
          </div>
          <button type="submit" className={s.submit}>
            Rechercher
          </button>
        </animated.form>
      </animated.div>

      <div className={s.wrapper}>
        <div className={s.in}>
          <div className={s.list}>
            <animated.div className={s.total} style={totalStyle}>
              <animated.span>
                {countStyle.total.to((x) => x.toFixed(0))}
              </animated.span>{' '}
              résultat
              {total! > 1 ? 's' : ''}
            </animated.div>

            {resultTransition((style, item) => (
              <animated.div key={item.uuid} className={s.item} style={style}>
                <PersonResult item={item} />
              </animated.div>
            ))}
          </div>
          <animated.div className={s.more} style={moreStyle}>
            D'autres résultats ont été trouvés. Vous pouvez affiner votre
            recherche pour les voir.
          </animated.div>
        </div>
      </div>
    </div>
  );
};
