import { PageHome, PageTree } from 'pages';

export const routes = [
  { path: '/', element: PageHome },
  { path: '/p/:person', element: PageTree },
  { path: '/p/:person/:pdepth/:cdepth', element: PageTree },
  // retrocompatibility
  { path: '/i/:person', element: PageTree },
  { path: '/i/:person/:pdepth/:cdepth', element: PageTree },
];
