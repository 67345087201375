import { IconChild } from './child';
import { IconClose } from './close';
import { IconCopy } from './copy';
import { IconDark } from './dark';
import { IconFemale } from './female';
import { IconFull } from './full';
import { IconHide } from './hide';
import { IconInfo } from './info';
import { IconLight } from './light';
import { IconMale } from './male';
import { IconMinus } from './minus';
import { IconNext } from './next';
import { IconParent } from './parent';
import { IconPlus } from './plus';
import { IconPrevious } from './previous';
import { IconSearch } from './search';
import { IconShow } from './show';

const icons = {
  IconChild,
  IconClose,
  IconCopy,
  IconDark,
  IconFemale,
  IconFull,
  IconHide,
  IconInfo,
  IconLight,
  IconMale,
  IconMinus,
  IconNext,
  IconParent,
  IconPlus,
  IconPrevious,
  IconSearch,
  IconShow,
};

export { IconChild };
export { IconClose };
export { IconCopy };
export { IconDark };
export { IconFemale };
export { IconFull };
export { IconHide };
export { IconInfo };
export { IconLight };
export { IconMale };
export { IconMinus };
export { IconNext };
export { IconParent };
export { IconPlus };
export { IconPrevious };
export { IconSearch };
export { IconShow };

export default icons;
