import { Person } from '@kavrillon/tree-core';

interface IProps {
  person: Person;
}

export const PersonLifeDates = ({ person: { birth, death } }: IProps) => {
  if (!birth && !death) return <></>;
  const bStr = birth?.date ? new Date(birth?.date).getFullYear() : '?';
  let dStr = '';
  if (death) {
    dStr = ' - ' + (death.date ? new Date(death.date).getFullYear() : '?');
  }
  return (
    <div>
      {bStr}
      {dStr}
    </div>
  );
};
