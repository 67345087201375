import { Icon } from './icon';

export const IconPrevious = () => (
  <Icon>
    <path d="M9.71729 5L3.00021 12L9.71729 19" />
    <line
      x1="1"
      y1="-1"
      x2="16.7331"
      y2="-1"
      transform="matrix(1 0 0 -1 3.26709 11.0317)"
    />
  </Icon>
);
