import { useEffect, useState } from 'react';

export const useMediaQuery = (minWidth: number) => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia(
      `screen and (min-width: ${minWidth}px)`
    );
    setMatches(mediaQuery.matches);

    const listener = (ev: any) => setMatches(ev.matches);
    mediaQuery.addEventListener('change', listener);

    return () => {
      mediaQuery.removeEventListener('change', listener);
    };
  }, []); // eslint-disable-line

  return matches;
};
