import s from './links.module.scss';

import { PersonNodeLink } from '..';
import { PersonLink } from '.';

interface IProps {
  links: PersonNodeLink[];
  title: string;
}

export const PersonLinks = ({ title, links }: IProps) => {
  if (links.length === 0) return <></>;

  return (
    <div className={s.wrapper}>
      <h2 className={s.title}>{title}</h2>
      <ul className={s.list}>
        {links.map((link) => (
          <li className={s.item} key={link.node.uuid}>
            <PersonLink link={link} />
          </li>
        ))}
      </ul>
    </div>
  );
};
