import { Person } from '@kavrillon/tree-core';

interface IProps {
  person: Person;
}

export const PersonDeathAge = ({ person: p }: IProps) => {
  if (p.birth?.approximative || p.death?.approximative) return <></>;
  if (!p.birth?.date || !p.death?.date) return <></>;

  const birth = new Date(p.birth.date);
  const death = new Date(p.death.date);
  const diff = new Date(death.getTime() - birth.getTime());

  const years = Math.abs(diff.getUTCFullYear() - 1970);
  const months = diff.getUTCMonth();
  const days = diff.getUTCDate() - 1;

  if (years > 1) return <>à {years} ans</>;
  if (years === 1) return <>à 1 an</>;
  if (months > 0) return <>à {months} mois</>;
  if (days > 1) return <>à {days} jours</>;
  return <>à 1 jour</>;
};
