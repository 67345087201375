import s from './ref.module.scss';

interface IProps {
  content: string;
  title: string;
  url?: string;
}

export const PersonRef = ({ content, title, url }: IProps) => (
  <div className={s.ref}>
    {title}&nbsp;:&nbsp;
    {url && (
      <a
        href={url}
        className={s.link}
        target="_blank"
        rel="noopener noreferrer"
      >
        {content}
      </a>
    )}
    {!url && content}
  </div>
);
