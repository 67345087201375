import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Notification, NotificationState } from '.';

const initialState: NotificationState = { notifications: [] };

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    add: (state, action: PayloadAction<Notification>) => {
      state.notifications.push(action.payload);
    },
    remove: (state, action: PayloadAction<number>) => {
      state.notifications = state.notifications.filter(
        (notif: Notification) => notif.id !== action.payload
      );
    },
  },
});

export const { add, remove } = notificationSlice.actions;
export default notificationSlice.reducer;
