import { Icon } from './icon';

export const IconInfo = () => (
  <Icon>
    <path d="M10 5H22" />
    <path d="M10 12H22" />
    <path d="M10 19H22" />
    <circle cx="3" cy="5" r="2" fill="var(--color-icon)" stroke="none" />
    <circle cx="3" cy="12" r="2" fill="var(--color-icon)" stroke="none" />
    <circle cx="3" cy="19" r="2" fill="var(--color-icon)" stroke="none" />
  </Icon>
);
