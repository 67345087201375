import s from './place.module.scss';

import { PersonEvent } from '@kavrillon/tree-core';

interface IProps {
  event: PersonEvent;
  link?: boolean;
}

const PLACE_SEPARATOR_FULL = ', ';
const PLACE_LINK = 'https://www.google.com/maps/search/?api=1&query=';

export const PersonEventPlace = ({ event: { place }, link = true }: IProps) => {
  if (!place) return <></>;
  return link ? (
    <a
      className={s.link}
      href={`${PLACE_LINK}${place.join(PLACE_SEPARATOR_FULL)}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {place[0]}
    </a>
  ) : (
    <span className={s.place}>{place[0]}</span>
  );
};
