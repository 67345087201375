import { Person } from '@kavrillon/tree-core';

interface IProps {
  person: Person;
}

export const PersonName = ({ person: p }: IProps) => {
  let firstname: string = '?';
  let lastname: string = '?';

  const hasFirstname = p.firstname && p.firstname.length > 0;
  const hasLastname = p.lastname && p.lastname.length > 0;

  if (!hasFirstname && !hasLastname) return <>Inconnu</>;
  if (hasFirstname) firstname = p.firstname!;
  if (hasLastname) lastname = p.lastname!;

  return (
    <>
      {firstname} {lastname}
    </>
  );
};
