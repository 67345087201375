import s from './logo.module.scss';

export const Logo = () => (
  <svg className={s.wrapper} x="0px" y="0px" viewBox="0 0 512 512">
    <path
      d="M459.933,444.604c-3.49-15.147-17.075-26.47-33.263-26.47c-15.113,0-28.237,9.702-32.657,24.149
			c-0.247,0.845-1.229,1.451-2.313,1.451c-1.289,0-2.185-1.28-2.432-1.681c-4.574-7.347-13.397-12.757-23.236-14.652
			c-3.533-15.078-17.092-26.334-33.229-26.334c-5.786,0-11.315,1.434-16.239,4.13c-6.366-7.834-16.043-12.663-26.428-12.663
			c-16.196,0-29.79,11.332-33.271,26.479c-6.477,1.502-12.467,4.881-17.092,9.762c-4.062,4.275-6.707,9.429-8.132,14.959h-6.665
			c10.837-41.19,14.618-80.776,13.824-117.154c2.261-5.359,10.436-15.778,21.171-28.032
			c120.644-2.62,183.765-122.812,183.765-187.614c0-4.71-3.823-8.533-8.533-8.533c-121.25,0-185.574,118.229-187.639,184.448
			c-3.772,4.343-7.287,8.465-10.24,12.134c-6.485-74.863-31.386-132.634-49.698-156.937C186.525,47.838,134.958,0,34.136,0
			c-4.719,0-8.533,3.823-8.533,8.533c0,94.857,50.278,143.531,149.265,144.93c24.388,33.954,71.083,154.667,31.352,294.033
			c-7.996,4.105-14.225,11.298-17.007,20.386c-0.247,0.845-1.229,1.451-2.313,1.451c-1.254,0-2.125-1.178-2.44-1.681
			c-4.565-7.339-13.389-12.757-23.228-14.652c-3.533-15.078-17.092-26.334-33.229-26.334c-5.786,0-11.315,1.434-16.239,4.13
			c-6.366-7.834-16.043-12.663-26.428-12.663c-16.196,0-29.79,11.332-33.271,26.479c-6.477,1.502-12.467,4.881-17.092,9.762
			c-6.511,6.852-9.822,15.846-9.327,25.31C26.593,497.809,42.2,512,61.178,512H324.27h128c18.816,0,34.133-15.309,34.133-34.133
			C486.403,461.679,475.071,448.094,459.933,444.604z M426.277,119.706c-4.924,57.003-56.073,148.668-149.751,160.563
			c22.084-23.74,46.387-47.787,51.345-51.738c2.321-1.289,4.011-3.644,4.343-6.494c0.538-4.676-2.825-8.909-7.509-9.446
			c-3.994-0.444-6.912-0.768-52.139,46.882c-1.801,1.894-3.405,3.635-5.112,5.461C280.11,205.705,334.083,124.655,426.277,119.706z
			 M173.835,138.428c-3.669,2.961-4.233,8.337-1.271,12.006c0.043,0.06,0.102,0.137,0.145,0.196
			c-27.307-29.815-72.294-60.885-72.747-61.201c-3.883-2.671-4.864-7.979-2.193-11.87c2.671-3.874,7.979-4.855,11.87-2.185
			c1.911,1.314,47.266,32.64,75.861,63.932c0.06,0.068,0.077,0.162,0.137,0.239C182.633,136.055,177.436,135.535,173.835,138.428z
			 M61.178,494.933c-9.728,0-18.022-7.236-18.492-16.137c-0.247-4.745,1.408-9.242,4.659-12.672c3.26-3.43,7.654-5.325,12.39-5.325
			c4.71,0,8.533-3.823,8.533-8.533c0-9.412,7.654-17.067,17.067-17.067c7.057,0,13.466,4.497,15.932,11.204
			c1.024,2.782,3.422,4.83,6.332,5.41c2.918,0.606,5.914-0.384,7.927-2.56c3.302-3.558,7.731-5.521,12.476-5.521
			c9.412,0,17.067,7.654,17.067,17.067c0,4.71,3.814,8.533,8.533,8.533c7.39,0,14.123,3.738,16.375,7.339
			c3.789,6.093,10.121,9.728,16.922,9.728c8.542,0,16.205-5.564,18.637-13.525c2.21-7.228,8.772-12.075,16.333-12.075h9.634
			c0,0,0.009,0.008,0.009,0.017c0.009,0.026,0.017,0.051,0.026,0.077c0.529,1.937,1.289,3.797,2.167,5.606
			c0.265,0.538,0.563,1.058,0.862,1.579c0.734,1.331,1.57,2.611,2.492,3.84c0.367,0.486,0.708,0.973,1.101,1.442
			c1.348,1.604,2.799,3.14,4.463,4.531c2.074,1.732,4.685,2.679,7.33,2.031c2.628-0.631,4.651-2.176,5.709-4.668
			c0.341-0.572,0.887-1.749,1.075-2.381c2.21-7.228,8.772-12.075,16.333-12.075c9.412,0,17.067,7.654,17.067,17.067
			c0,4.71,3.814,8.533,8.533,8.533c1.417,0,2.782,0.23,4.104,0.555c0.384,0.102,0.751,0.23,1.126,0.35
			c0.956,0.316,1.877,0.708,2.756,1.178c0.324,0.171,0.666,0.333,0.981,0.529c1.126,0.7,2.193,1.493,3.123,2.432
			c0.026,0.026,0.051,0.068,0.085,0.102c0.887,0.904,1.647,1.937,2.313,3.021c0.077,0.119,0.128,0.247,0.196,0.367H61.178z"
    />
  </svg>
);
