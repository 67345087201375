import s from './refs.module.scss';

import { useDispatch } from 'react-redux';
import { IconCopy } from 'shared/icons';
import { addNotification } from 'modules/notification';
import { PersonNode } from '..';
import { PersonRef } from '.';

interface IProps {
  node: PersonNode;
}

export const PersonRefs = ({ node }: IProps) => {
  const dispatch = useDispatch();
  const p = node.data;
  const hasSource = p.url;
  const hasBirthRef = p.birth?.reference;
  const hasDeathRef = p.death?.reference;
  const hasUnionsRef = node.unions?.reduce(
    (_, { data: { event } }) =>
      !!event?.reference && event.reference?.length > 0,
    false
  );

  const copy = (text: string) => {
    navigator.clipboard.writeText(text);
    addNotification(
      dispatch,
      "L'identifiant a été copié dans le presse papier"
    );
  };

  return (
    <div className={s.wrapper}>
      <h2 className={s.title}>Références</h2>

      <div className={s.id}>
        <div className={s.label}>ID :</div>
        <div className={s.value}>
          <button
            className={s.btn}
            onClick={() => copy(node.uuid)}
            title={'Copier'}
          >
            <span className={s.text}>{node.uuid}</span>
            <span className={s.icon}>
              <IconCopy />
            </span>
          </button>
        </div>
      </div>

      {node.treeId && <div className={s.item}>SOSA : {node.treeId}</div>}

      {hasSource && (
        <div className={s.item}>
          <PersonRef content={'geneanet.org'} title={'Import'} url={p.url} />
        </div>
      )}

      {hasBirthRef && (
        <div className={s.item}>
          <PersonRef
            content={p.birth!.reference!}
            title={'Naissance'}
            url={p.birth!.url}
          />
        </div>
      )}

      {hasUnionsRef &&
        node.unions!.map(
          ({ data: { event } }, i: number) =>
            event!.reference && (
              <div className={s.item} key={i}>
                <PersonRef
                  content={event!.reference!}
                  title={'Mariage'}
                  url={event!.url}
                />
              </div>
            )
        )}

      {hasDeathRef && (
        <div className={s.item}>
          <PersonRef
            content={p.death!.reference!}
            title={'Décès'}
            url={p.death!.url}
          />
        </div>
      )}
    </div>
  );
};
