import s from './result.module.scss';

import { Link } from 'react-router-dom';
import { PersonItem } from '@kavrillon/tree-core';
import { IconFemale, IconMale } from 'shared/icons';
import { PersonName, PersonInfo } from 'modules/person';

interface IProps {
  item: PersonItem;
}

export const PersonResult = ({ item: { uuid, data } }: IProps) => {
  return (
    <Link to={`/p/${uuid}`} className={s.wrapper}>
      <div className={s.top}>
        <div className={s.topline}>
          <div
            className={s.gender}
            title={data.gender === 'F' ? 'Femme' : 'Homme'}
          >
            {data.gender === 'F' ? <IconFemale /> : <IconMale />}
          </div>
          <h2 className={s.name}>
            <PersonName person={data} />
          </h2>
        </div>
      </div>
      <div className={s.info}>
        <PersonInfo person={data} linkOnPlaces={false} ellipsis={true} />
      </div>
    </Link>
  );
};
