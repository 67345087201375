import { Icon } from './icon';

export const IconFemale = () => (
  <Icon>
    <path
      d="M18.5 8.5C18.5 12.0899 15.5899 15 12 15C8.41015 15 5.5 12.0899 5.5 8.5C5.5 4.91015 8.41015 2 12 2C15.5899 2 18.5 4.91015 18.5 8.5Z"
      strokeWidth="3"
    />
    <path d="M7.5 19H16.5" strokeWidth="3" />
    <path d="M12 22L12 15" strokeWidth="3" />
  </Icon>
);
