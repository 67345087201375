import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import { uiSlice } from './slice';
import { notificationSlice } from 'modules/notification';
import { personNodeSlice } from 'modules/person-node';
import { personApi } from './services/person';
import { pictureSlice } from 'modules/picture';
import { treeSlice } from 'modules/tree';

const reducer = {
  ui: uiSlice.reducer,
  picture: pictureSlice.reducer,
  notifier: notificationSlice.reducer,
  personNode: personNodeSlice.reducer,
  tree: treeSlice.reducer,
  [personApi.reducerPath]: personApi.reducer,
};

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(personApi.middleware),
});

setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
