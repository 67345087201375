import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UIState, ThemeName } from './types';
import { THEMES } from 'config/themes';

const initialState: UIState = { theme: THEMES[0] };

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setTheme: (state, { payload }: PayloadAction<ThemeName>) => {
      state.theme = THEMES.find((t) => t.name === payload) || THEMES[0];
    },
  },
});

export const { setTheme } = uiSlice.actions;
export default uiSlice.reducer;
