import { Icon } from './icon';

export const IconMale = () => (
  <Icon>
    <path
      d="M15 15.5C15 19.0899 12.0899 22 8.5 22C4.91015 22 2 19.0899 2 15.5C2 11.9101 4.91015 9 8.5 9C12.0899 9 15 11.9101 15 15.5Z"
      strokeWidth="3"
    />
    <path d="M15 2H22V9" strokeWidth="3" />
    <path d="M13.5 10.5L22 2" strokeWidth="3" />
  </Icon>
);
