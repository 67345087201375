import s from './info.module.scss';

import { Person } from '@kavrillon/tree-core';
import {
  PersonBirthLabel,
  PersonEventDate,
  PersonEventPlace,
  PersonDeathLabel,
  PersonDeathAge,
} from '..';

interface IProps {
  person: Person;
  linkOnPlaces?: boolean;
  ellipsis?: boolean;
}

export const PersonInfo = ({
  person,
  linkOnPlaces = true,
  ellipsis = false,
}: IProps) => (
  <ul className={s.wrapper + ` ${ellipsis ? s._ellipsis : ''}`}>
    {person.birth && (
      <li className={s.birth}>
        <div className={s.birthdate}>
          <PersonBirthLabel person={person} />
          &nbsp;
          <PersonEventDate event={person.birth} />
        </div>
        {person.birth.place && (
          <div className={s.birthplace}>
            &nbsp;à&nbsp;
            <PersonEventPlace event={person.birth} link={linkOnPlaces} />
          </div>
        )}
      </li>
    )}
    {person.death && (
      <li className={s.death}>
        <div className={s.deathdate}>
          <PersonDeathLabel person={person} />
          &nbsp;
          <PersonEventDate event={person.death} />
          &nbsp;
        </div>
        <div className={s.deathage}>
          <PersonDeathAge person={person} />
          &nbsp;
        </div>
        {person.death.place && (
          <div className={s.deathplace}>
            à&nbsp;
            <PersonEventPlace event={person.death} link={linkOnPlaces} />
          </div>
        )}
      </li>
    )}
    {person.infos && <li className={s.work}>{person.infos}</li>}
  </ul>
);
