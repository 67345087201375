import s from './union.module.scss';

import { PersonEventDate } from 'modules/person';
import { PersonLink, PersonNodeUnionLink } from '..';

interface IProps {
  union: PersonNodeUnionLink;
}

export const PersonUnion = ({ union }: IProps) => (
  <div className={s.wrapper}>
    <div className={s.relation}>
      <div className={s.person}>
        <PersonLink link={union} date={false} />
      </div>
    </div>
    {union.data.event?.date && (
      <div className={s.date}>
        &nbsp;
        <PersonEventDate event={union.data.event} />
      </div>
    )}
  </div>
);
