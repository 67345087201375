import {
  Person,
  ParentData,
  UnionData,
  PersonDatabase,
} from '@kavrillon/tree-core';
import { TreeDataLoader } from 'modules/tree';

export class PersonTreeLoader extends TreeDataLoader<
  Person,
  ParentData,
  UnionData
> {
  constructor(data: PersonDatabase) {
    super(
      data,
      { fn: (v) => v.node.data.gender || null, type: 'desc' },
      { fn: (v) => v.node.data.birth?.date || null },
      { fn: (v) => v.node.data.birth?.date || null },
      { fn: (v) => v.data?.event?.date || null }
    );
  }
}
