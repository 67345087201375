import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { PersonDatabase } from '@kavrillon/tree-core';

export const personApi = createApi({
  reducerPath: 'personApi',
  baseQuery: fetchBaseQuery({ baseUrl: '/' }),
  endpoints: (builder) => ({
    getPersons: builder.query<PersonDatabase, void>({
      query: () => `data/persons.json`,
    }),
  }),
});

export const { useGetPersonsQuery } = personApi;
