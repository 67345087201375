import { Dispatch } from '@reduxjs/toolkit';
import { add, remove } from './slice';

let nextId = 0;

export const addNotification = (
  dispatch: Dispatch,
  message: string,
  timeout: number = 5000
) => {
  const id = nextId++;
  dispatch(add({ id, message }));
  setTimeout(() => dispatch(remove(id)), timeout);
};
