import s from './link.module.scss';

import { Link, useParams } from 'react-router-dom';
import { PersonLifeDates, PersonName } from 'modules/person';
import { PersonNodeLink } from '..';

interface IProps {
  link: PersonNodeLink;
  date?: boolean;
}

export const PersonLink = ({ link: { node }, date = true }: IProps) => {
  const { pdepth, cdepth } = useParams();

  return (
    <Link
      className={s.link}
      to={`/p/${node.uuid}${pdepth ? `/${pdepth}` : ''}${
        cdepth ? `/${cdepth}` : ''
      }`}
    >
      <div className={s.name}>
        <PersonName person={node.data} />
      </div>
      {date && (
        <div className={s.date}>
          <PersonLifeDates person={node.data} />
        </div>
      )}
    </Link>
  );
};
