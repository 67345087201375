import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PersonNode, PersonNodeState } from '.';

const initialState: PersonNodeState = {
  detailed: null,
};

export const personNodeSlice = createSlice({
  name: 'personNode',
  initialState,
  reducers: {
    setDetailedNode: (state, action: PayloadAction<PersonNode | null>) => {
      state.detailed = action.payload;
    },
  },
});

export const { setDetailedNode } = personNodeSlice.actions;
export default personNodeSlice.reducer;
