import s from './detail.module.scss';

import { useDispatch } from 'react-redux';
import { Person } from '@kavrillon/tree-core';
import { IconFemale, IconMale } from 'shared/icons';
import { setPictures, Thumbnail } from 'modules/picture';
import { PersonInfo, PersonName, PersonNickname } from '..';

interface IProps {
  person: Person;
}

const PATH = '/data/images/';

export const PersonDetail = ({ person }: IProps) => {
  const dispatch = useDispatch();
  const thumbs = person.pictures ? person.pictures.map((p) => PATH + p) : [];

  return (
    <div className={s.detail}>
      <div className={s.top}>
        <div
          className={s.gender}
          title={person.gender === 'F' ? 'Femme' : 'Homme'}
        >
          {person.gender === 'F' ? <IconFemale /> : <IconMale />}
        </div>
        <h1 className={s.name}>
          <PersonName person={person} /> <PersonNickname person={person} />
        </h1>
      </div>
      <div className={s.infos}>
        <PersonInfo person={person} />
      </div>

      {thumbs.length > 0 && (
        <div className={s.pictures}>
          {thumbs.map((thumb) => (
            <div
              className={s.thumb}
              key={thumb}
              onClick={() => dispatch(setPictures(thumbs))}
            >
              <Thumbnail file={thumb} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
