import './app.scss';

import { Route, Routes } from 'react-router-dom';
import { routes } from 'config/routes';
import { ThemeWatcher } from 'shared/components';
import { Notifier } from 'modules/notification';

const App = () => {
  return (
    <div className="app">
      <Notifier />
      <ThemeWatcher />
      <Routes>
        {routes.map((route, key) => (
          <Route key={key} path={route.path} element={<route.element />} />
        ))}
      </Routes>
    </div>
  );
};

export default App;
