import s from './menu.module.scss';

import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { animated, useTransition } from 'react-spring';
import { rightToLeftTransition } from 'config/animation';
import { RootState } from 'shared/store';
import { setTheme } from 'shared/slice';
import icons from 'shared/icons';
import { useKeyPress } from 'shared/hooks';
import {
  AnyNode,
  addChildLevel,
  addParentLevel,
  removeChildLevel,
  removeParentLevel,
} from '..';

interface IProps {
  rootNode: AnyNode;
  onSearch: () => void;
  onCenter: () => void;
  onShowAll: () => void;
  onHideAll: () => void;
  onToggleDetail?: () => void;
}

export const Menu = (props: IProps) => {
  const dispatch = useDispatch();
  const theme = useSelector((state: RootState) => state.ui.theme.name);
  const depth = useSelector((state: RootState) => state.tree.depth);
  const [childOpened, setChildOpened] = useState(false);
  const [parentOpened, setParentOpened] = useState(false);

  const isDark = theme === 'dark';

  // keyboard shortcuts
  useKeyPress(['t'], () => toggleTheme());
  useKeyPress(['r'], () => props.onSearch());
  useKeyPress(['o'], () => props.onShowAll());
  useKeyPress(['f'], () => props.onHideAll());
  useKeyPress(['p'], () => dispatch(addParentLevel()));
  useKeyPress(['m'], () => dispatch(removeParentLevel()));
  useKeyPress(['u'], () => dispatch(addChildLevel()));
  useKeyPress(['j'], () => dispatch(removeChildLevel()));
  useKeyPress(['c'], () => props.onCenter());
  useKeyPress(['i'], () => props.onToggleDetail && props.onToggleDetail());

  const toggleChild = () => {
    setParentOpened(!childOpened ? false : parentOpened);
    setChildOpened(!childOpened);
  };

  const toggleParent = () => {
    setChildOpened(!parentOpened ? false : childOpened);
    setParentOpened(!parentOpened);
  };

  const toggleTheme = () => dispatch(setTheme(isDark ? 'light' : 'dark'));
  
  const parentTransition = useTransition(parentOpened, rightToLeftTransition);
  const childTransition = useTransition(childOpened, rightToLeftTransition);

  return (
    <div className={s.wrapper}>
      <div className={s.actions}>
        <div className={s.group}>
          <button
            className={s.cta}
            onClick={() => props.onSearch()}
            title={'Rechercher une personne (R)'}
          >
            <icons.IconSearch />
          </button>
          <button
            className={s.cta}
            onClick={toggleTheme}
            title={'Changer de thème (T)'}
          >
            {isDark ? <icons.IconLight /> : <icons.IconDark />}
          </button>
          <button
            className={s.cta}
            onClick={() => props.onShowAll()}
            title={'Tout ouvrir (O)'}
          >
            <icons.IconShow />
          </button>
          <button
            className={s.cta}
            onClick={() => props.onHideAll()}
            title={'Tout fermer (F)'}
          >
            <icons.IconHide />
          </button>
        </div>
        <div className={s.group}>
          <button
            className={s.cta}
            onClick={toggleParent}
            title={'Ajouter / supprimer un niveau de parents (P/M)'}
          >
            <icons.IconParent />
          </button>

          {parentTransition(
            (style, item) =>
              item && (
                <animated.div style={style} className={s.subgroup}>
                  <button
                    className={s.subcta}
                    onClick={() => dispatch(addParentLevel())}
                    title={'Ajouter un niveau de parents (P)'}
                  >
                    <icons.IconPlus />
                  </button>
                  <button
                    className={s.subcta}
                    onClick={() => dispatch(removeParentLevel())}
                    title={'Supprimer un niveau de parents (M)'}
                    disabled={depth.parent === 1}
                  >
                    <icons.IconMinus />
                  </button>
                </animated.div>
              )
          )}
          <button
            className={s.cta}
            onClick={toggleChild}
            title={"Ajouter / supprimer un niveau d'enfants (U/J)"}
          >
            <icons.IconChild />
          </button>
          {childTransition(
            (style, item) =>
              item && (
                <animated.div style={style} className={s.subgroup}>
                  <button
                    className={s.subcta}
                    onClick={() => dispatch(removeChildLevel())}
                    title={"Supprimer un niveau d'enfants (J)"}
                    disabled={depth.child === 1}
                  >
                    <icons.IconMinus />
                  </button>
                  <button
                    className={s.subcta}
                    onClick={() => dispatch(addChildLevel())}
                    title={"Ajouter un niveau d'enfants (U)"}
                  >
                    <icons.IconPlus />
                  </button>
                </animated.div>
              )
          )}
        </div>
        <div className={s.group}>
          <button
            className={s.cta}
            onClick={() => props.onCenter()}
            title={"Recentrer l'arbre (C)"}
          >
            <icons.IconFull />
          </button>
          {props.onToggleDetail && (
            <button
              className={s.cta}
              onClick={() => props.onToggleDetail!()}
              title={'Afficher/masquer les informations (I)'}
            >
              <icons.IconInfo />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
