import s from './notifier.module.scss';

import { useSelector } from 'react-redux';
import { animated, config, useTransition } from 'react-spring';
import { RootState } from 'shared/store';
import { Notif } from '.';

export const Notifier = () => {
  const notifs = useSelector(
    (state: RootState) => state.notifier.notifications
  );

  const transition = useTransition(notifs, {
    from: { x: 0, y: -200, opacity: 0 },
    enter: { x: 0, y: 0, opacity: 1, height: 48 },
    leave: { x: 0, y: -200, opacity: 0, height: 0 },
    config: config.default,
  });

  return (
    <div className={s.notifier}>
      {transition((style, item) =>
        item ? (
          <animated.div className={s.item} style={style}>
            <Notif message={item.message} />
          </animated.div>
        ) : (
          ''
        )
      )}
    </div>
  );
};
