import { Icon } from './icon';

export const IconFull = () => (
  <Icon>
    <path d="M15.2138 3.64911L20.4882 3.64911L20.4882 8.93995" />
    <path d="M19.5117 4.51221L14 10.0002" />
    <path d="M20.3704 14.7142L20.3699 19.9887L15.079 19.9882" />
    <path d="M19.5068 19.012L13.9999 13.9999" />
    <path d="M8.91157 20.0115L3.63713 20.0115L3.63714 14.7207" />
    <path d="M4.61572 18.9109L10 14" />
    <path d="M3.62877 8.9154L3.62926 3.64096L8.9201 3.64146" />
    <path d="M4.72986 4.61963L9.99988 10" />
  </Icon>
);
