import s from './unions.module.scss';

import { PersonNodeUnionLink } from '..';
import { PersonUnion } from '.';

interface IProps {
  unions: PersonNodeUnionLink[];
}

export const PersonUnions = ({ unions }: IProps) => (
  <>
    {unions.length > 0 && (
      <div>
        <h2 className={s.title}>Mariage{unions.length > 1 ? 's' : ''}</h2>
        {unions.map((u, i) => (
          <div key={i}>
            <PersonUnion union={u} />
          </div>
        ))}
      </div>
    )}
  </>
);
