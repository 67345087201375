import s from './modal.module.scss';

import { ReactNode, useCallback, useEffect, useState } from 'react';
import { animated, useSpring } from 'react-spring';
import { defaultOffStyle, defaultOnStyle } from 'config/animation';
import { IconClose } from 'shared/icons';

interface IProps {
  children: ReactNode;
  onClose: Function;
}

export const Modal = ({ children, onClose }: IProps) => {
  const [removing, setRemoving] = useState<boolean>(false);

  const onKeyDown = useCallback(
    (event: any) => {
      if (event.keyCode === 27) {
        close();
      }
    },
    [onClose] // eslint-disable-line
  );

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);

    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [onKeyDown]);

  const close = () => {
    setRemoving(true);
    setTimeout(() => onClose(), 320);
  };

  const style = useSpring(
    removing
      ? { from: defaultOnStyle, to: defaultOffStyle }
      : { from: defaultOffStyle, to: defaultOnStyle }
  );

  return (
    <animated.div className={s.modal} style={style}>
      <button className={s.close} onClick={() => close()} title={'Fermer'}>
        <IconClose />
      </button>
      <div className={s.in}>{children}</div>
    </animated.div>
  );
};
