import './theme-watcher.scss';

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Theme } from 'shared/types';
import { RootState } from 'shared/store';
import { setTheme } from 'shared/slice';

const stored = localStorage.getItem('theme');

const prefersDark =
  window.matchMedia &&
  window.matchMedia('(prefers-color-scheme: dark)').matches;

const defaultDark = stored === 'dark' || (stored === null && prefersDark);

const applyTheme = (theme: Theme) => {
  localStorage.setItem('theme', theme.name);
  document.documentElement.setAttribute('data-theme', theme.name);
  document
    .querySelector('meta[name="theme-color"]')
    ?.setAttribute('content', theme.color);
};

export const ThemeWatcher = () => {
  const dispatch = useDispatch();
  const theme = useSelector((state: RootState) => state.ui.theme);

  useEffect(() => {
    if (defaultDark) dispatch(setTheme('dark'));
  }, []); // eslint-disable-line

  useEffect(() => applyTheme(theme), [theme]);
  return <></>;
};
