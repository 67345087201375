import { useCallback, useEffect, useLayoutEffect, useRef } from 'react';

export const useKeyPress = (
  keys: string[],
  callback: (arg: Event) => void,
  node: HTMLElement | null = null
) => {
  const callbackRef = useRef(callback);

  useLayoutEffect(() => {
    callbackRef.current = callback;
  });

  const handleKeyPress = useCallback(
    (event: Event) => {
      if (!isKeyBoardevent(event)) return;

      if (keys.some((key) => event.key === key)) {
        callbackRef.current(event);
      }
    },
    [keys]
  );

  useEffect(() => {
    const targetNode = node ?? document;
    targetNode && targetNode.addEventListener('keydown', handleKeyPress);

    return () =>
      targetNode && targetNode.removeEventListener('keydown', handleKeyPress);
  }, [handleKeyPress, node]);
};

const isKeyBoardevent = (event: Event): event is KeyboardEvent => {
  return 'key' in event;
};
