import s from './thumbnail.module.scss';

interface IProps {
  file: string;
}

export const Thumbnail = ({ file }: IProps) => (
  <div className={s.wrapper}>
    <img className={s.img} src={file} alt="" />
  </div>
);
