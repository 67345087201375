import s from './tree.module.scss';

import { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import { useDidMountEffect } from 'shared/hooks';
import { Node, AnyNode, TreeRenderer, NodeRenderer, TreeDepth } from '..';
import { Menu } from '.';

interface IProps {
  root: Node<unknown, unknown, unknown>;
  depth: TreeDepth;
  nodeRenderer: NodeRenderer;
  search: () => void;
  showNode?: (n: AnyNode) => void;
  showDetail?: (n: AnyNode) => void;
  toggleDetail?: () => void;
}

export const Tree = ({
  root,
  depth,
  nodeRenderer,
  showNode,
  showDetail,
  toggleDetail,
  search,
}: IProps) => {
  const refSvg = useRef(null);
  const [renderer, setRenderer] = useState<TreeRenderer | null>(null);

  useEffect(() => {
    const svg = refSvg.current;
    if (!svg) return;

    d3.select(svg);

    const renderer = new TreeRenderer(svg, nodeRenderer, showNode, showDetail);
    setRenderer(renderer);
  }, []); //eslint-disable-line

  useDidMountEffect(() => {
    renderer?.load(root, depth);
  }, [renderer, root]); //eslint-disable-line

  return (
    <div className={s.wrapper}>
      <svg className={s.svg} ref={refSvg}>
        <defs>
          <radialGradient id="Root" cx="0.9" cy="0.9" r="0.9">
            <stop offset="0%" stopColor="var(--color-node-root-from)" />
            <stop offset="100%" stopColor="var(--color-node-root-to)" />
          </radialGradient>
          <radialGradient id="Parent" cx="0.9" cy="0.9" r="0.9">
            <stop offset="0%" stopColor="var(--color-node-parent-from)" />
            <stop offset="100%" stopColor="var(--color-node-parent-to)" />
          </radialGradient>
          <radialGradient id="ParentEnd" cx="0.9" cy="0.9" r="0.9">
            <stop offset="0%" stopColor="var(--color-node-parentend-from)" />
            <stop offset="100%" stopColor="var(--color-node-parentend-to)" />
          </radialGradient>
          <radialGradient id="Child" cx="0.9" cy="0.9" r="0.9">
            <stop offset="0%" stopColor="var(--color-node-child-from)" />
            <stop offset="100%" stopColor="var(--color-node-child-to)" />
          </radialGradient>
          <radialGradient id="Sibling" cx="0.9" cy="0.9" r="0.9">
            <stop offset="0%" stopColor="var(--color-node-sibling-from)" />
            <stop offset="100%" stopColor="var(--color-node-sibling-to)" />
          </radialGradient>
          <radialGradient id="Union" cx="0.9" cy="0.9" r="0.9">
            <stop offset="0%" stopColor="var(--color-node-union-from)" />
            <stop offset="100%" stopColor="var(--color-node-union-to)" />
          </radialGradient>

          <pattern
            id="Show"
            viewBox="0,0,24,24"
            width="1"
            height="1"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path
              d="M1.33497 13.2561C0.888345 12.4782 0.888342 11.522 1.33497 10.7441C3.68496 6.65097 7.44378 4 11.6798 4C15.9158 4 19.6746 6.65094 22.0246 10.744C22.4712 11.5219 22.4712 12.4781 22.0246 13.256C19.6746 17.3491 15.9158 20 11.6798 20C7.44377 20 3.68497 17.3491 1.33497 13.2561Z"
              stroke="var(--color-node-button)"
              fill="none"
            />
            <circle
              cx="11.6797"
              cy="12"
              r="3"
              stroke="var(--color-node-button)"
              fill="none"
            />
          </pattern>
          <pattern
            id="Hide"
            viewBox="0,0,24,24"
            width="1"
            height="1"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M5.67969 3L21.6797 19" stroke="var(--color-node-button)" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M14.0546 4.79034C14.2347 4.26823 14.8039 3.99094 15.326 4.171C18.4478 5.24759 21.0835 7.65277 22.8918 10.8024C23.3668 11.6297 23.4799 12.6014 23.2316 13.4974C23.084 14.0296 22.5329 14.3414 22.0007 14.1939C21.4685 14.0463 21.1567 13.4953 21.3042 12.9631C21.4117 12.5756 21.3626 12.1557 21.1573 11.7982C19.5394 8.98025 17.2514 6.95057 14.674 6.06172C14.1519 5.88167 13.8746 5.31245 14.0546 4.79034ZM8.7503 4.94112C8.95814 5.45281 8.71183 6.0361 8.20014 6.24394C5.82167 7.21006 3.71737 9.15927 2.2022 11.7983C1.9326 12.2679 1.9326 12.8449 2.2022 13.3145C4.42466 17.1854 7.89339 19.5564 11.6798 19.5564C14.367 19.5564 16.8801 18.3688 18.9146 16.299C19.3017 15.9051 19.9349 15.8996 20.3288 16.2868C20.7226 16.6739 20.7281 17.3071 20.341 17.7009C17.9956 20.0871 14.9897 21.5564 11.6798 21.5564C6.99415 21.5564 2.94527 18.6255 0.467746 14.3103C-0.155911 13.2241 -0.155916 11.8888 0.467735 10.8025C2.16221 7.85116 4.58162 5.55506 7.44748 4.39097C7.95917 4.18313 8.54246 4.42944 8.7503 4.94112Z"
              fill="var(--color-node-button)"
            />
            <path
              d="M14.5892 11.8251C14.748 12.457 14.6968 13.1233 14.4434 13.7235C14.1899 14.3236 13.748 14.825 13.1844 15.1517C12.6208 15.4785 11.9662 15.6129 11.3194 15.5347C10.6726 15.4564 10.0689 15.1698 9.59949 14.7181C9.13007 14.2663 8.82045 13.6741 8.71743 13.0308C8.61441 12.3875 8.7236 11.7282 9.02848 11.1524C9.33337 10.5767 9.81738 10.1159 10.4074 9.83956C10.9973 9.56327 11.6612 9.48653 12.2987 9.62095"
              stroke="var(--color-node-button)"
              fill="none"
            />
          </pattern>
          <pattern
            id="Info"
            viewBox="0,0,24,24"
            width="1"
            height="1"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M10 5H22" stroke="var(--color-node-button)" />
            <path d="M10 12H22" stroke="var(--color-node-button)" />
            <path d="M10 19H22" stroke="var(--color-node-button)" />
            <circle cx="3" cy="5" r="2" fill="var(--color-node-button)" />
            <circle cx="3" cy="12" r="2" fill="var(--color-node-button)" />
            <circle cx="3" cy="19" r="2" fill="var(--color-node-button)" />
          </pattern>
        </defs>
      </svg>
      <div className={s.menu}>
        {renderer && (
          <Menu
            rootNode={root}
            onSearch={search}
            onCenter={() => renderer.fitZoom()}
            onShowAll={() => renderer.uncollapseAll()}
            onHideAll={() => renderer.collapseAll()}
            onToggleDetail={toggleDetail}
          />
        )}
      </div>
    </div>
  );
};
