import { PersonEvent } from '@kavrillon/tree-core';
import { ellipse } from 'shared/utils/string';
import { NodeRenderer, RenderingNode } from 'modules/tree';

const FONT_SIZE = 14;
const TEXT_PADDING = 5;
const TEXT_PADDING_S = 3;
const TEXT_PADDING_L = 10;

export class PersonNodeRenderer implements NodeRenderer {
  size = 160;
  padding = 10;
  radius = 8;

  render(nodes: any): void {
    const { size, padding } = this;

    // text position depending on the center of the rect (0/0)
    let textPosition = -(size / 2) + padding + FONT_SIZE;

    // firstname
    nodes
      .append('text')
      .attr('x', 0)
      .attr('y', 0)
      .attr('dx', -size / 2 + padding)
      .attr('dy', textPosition)
      .attr('font-size', FONT_SIZE)
      .attr('text-anchor', 'left')
      .attr('width', size - padding * 2)
      .text(({ data: { data } }: RenderingNode) =>
        ellipse(data.firstname || '?', 14)
      );

    textPosition += FONT_SIZE + TEXT_PADDING;

    // lastname
    nodes
      .append('text')
      .attr('x', 0)
      .attr('y', 0)
      .attr('dx', -size / 2 + padding)
      .attr('dy', textPosition)
      .attr('font-size', FONT_SIZE)
      .attr('text-anchor', 'left')
      .attr('width', size - padding * 2)
      .text(({ data: { data } }: RenderingNode) =>
        ellipse(data.lastname || '?', 16)
      );

    textPosition += FONT_SIZE + TEXT_PADDING_L;

    // birth date
    nodes
      .append('text')
      .attr('x', 0)
      .attr('y', 0)
      .attr('dx', -size / 2 + padding)
      .attr('dy', textPosition)
      .attr('font-size', FONT_SIZE)
      .attr('text-anchor', 'left')
      .attr('width', size - padding * 2)
      .attr('fill', 'var(--color-node-person-light)')
      .text(({ data: { data } }: RenderingNode) =>
        ellipse(this.getEventResume(data.birth), 16)
      );

    textPosition += FONT_SIZE + TEXT_PADDING_S;

    // death date
    nodes
      .append('text')
      .attr('x', 0)
      .attr('y', 0)
      .attr('dx', -size / 2 + padding)
      .attr('dy', textPosition)
      .attr('font-size', FONT_SIZE)
      .attr('text-anchor', 'left')
      .attr('width', size - padding * 2)
      .attr('fill', 'var(--color-node-person-light)')
      .text(({ data: { data } }: RenderingNode) =>
        ellipse(this.getEventResume(data.death), 16)
      );

    textPosition += FONT_SIZE + TEXT_PADDING;

    // infos
    nodes
      .append('text')
      .attr('x', 0)
      .attr('y', 0)
      .attr('dx', -size / 2 + padding)
      .attr('dy', textPosition)
      .attr('font-size', FONT_SIZE)
      .attr('text-anchor', 'left')
      .attr('width', size - padding * 2)
      .attr('fill', 'var(--color-node-person-light)')
      .text(({ data: { data } }: RenderingNode) =>
        ellipse(data.infos || '', 18)
      );

    // icon for person pic
    const pic = nodes
      .append('g')
      .attr('transform', 'scale(0.7) translate(75,-100)')
      .attr('fill', 'none')
      .attr('stroke', 'var(--color-node-person-light)')
      .attr('stroke-width', '1')
      .attr('stroke-linecap', 'round')
      .attr('stroke-linejoin', 'round');

    pic
      .append('path')
      .attr(
        'd',
        'M2 9.25694C2 7.45818 3.45818 6 5.25694 6V6C6.34591 6 7.36283 5.45576 7.96688 4.54969L8 4.5C8.62474 3.56288 9.6765 3 10.8028 3H13.1972C14.3235 3 15.3753 3.56288 16 4.5L16.0331 4.54969C16.6372 5.45576 17.6541 6 18.7431 6V6C20.5418 6 22 7.45818 22 9.25694V17C22 19.2091 20.2091 21 18 21H6C3.79086 21 2 19.2091 2 17V9.25694Z'
      )
      .attr('visibility', this.getPictureVisibility);

    pic
      .append('circle')
      .attr('cx', '12')
      .attr('cy', '13')
      .attr('r', '4')
      .attr('visibility', this.getPictureVisibility);
  }

  private getEventResume = (event: PersonEvent | null): string => {
    const date = event?.date ? new Date(event.date).getFullYear() : null;
    const place =
      event?.place && event?.place.length > 0 ? event?.place[0] : null;

    if (date && place) return `${date}, ${place}`;
    if (date) return '' + date;
    if (place) return '' + place;
    return '';
  };

  private getPictureVisibility = ({ data: { data } }: RenderingNode) => {
    return data.pictures?.length > 0 ? 'visible' : 'hidden';
  };
}
