import { createSlice } from '@reduxjs/toolkit';
import { PersonDatabaseState } from '.';

const initialState: PersonDatabaseState = { data: [] };

export const databaseSlice = createSlice({
  name: 'personDatabase',
  initialState,
  reducers: {},
});

export default databaseSlice.reducer;
