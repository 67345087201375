import { Icon } from './icon';

export const IconLight = () => (
  <Icon>
    <circle cx="12" cy="12" r="6" />
    <path d="M12 1V2M12 23L12 22" />
    <path d="M4.22168 19.7783L4.92879 19.0712M19.778 4.22197L19.0709 4.92908" />
    <path d="M4.22168 4.22168L4.92879 4.92879M19.778 19.778L19.0709 19.0709" />
    <path d="M1 12H2M23 12L22 12" />
  </Icon>
);
